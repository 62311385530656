import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="Om Förstärkeriet" />
    <h2>Om</h2>
    <p>
      Förstärkeriet består av Saga Rodrick som är en kreativ och driven
      ljudtekniker.
    </p>
    <p>
      Saga har läst radioproduktion på Malmö Universitet, musikproduktion på
      Lunds Universitet samt utbildat sig till ljudtekniker på Piteå
      Musikhögskola. Mellan 2017-2019 har hon arbetat på Sveriges Radio men
      söker nu efter nya äventyr och samarbeten.
    </p>
    <p>
      Förstärkeriet erbjuder ljuddesign, röstinspelning, tramp, ljudredigering,
      audio branding, mix av musik och postproduktion.
    </p>
  </Layout>
)

export default About
